// extracted by mini-css-extract-plugin
export var articles = "on-site-service-hs-module--articles--4cf95";
export var articlesHeader = "on-site-service-hs-module--articles-header--3063a";
export var bottomWrapper = "on-site-service-hs-module--bottom-wrapper--1f695";
export var buttonWrapper = "on-site-service-hs-module--button-wrapper--8cb5e";
export var deleteArticle = "on-site-service-hs-module--delete-article--10277";
export var desiredDate = "on-site-service-hs-module--desired-date--0d2e4";
export var disclaimer = "on-site-service-hs-module--disclaimer--b8c5a";
export var header = "on-site-service-hs-module--header--bcd49";
export var onSiteService = "on-site-service-hs-module--on-site-service--d0363";
export var response = "on-site-service-hs-module--response--329e6";
export var responseError = "on-site-service-hs-module--response-error--9ca47";
export var responseSuccess = "on-site-service-hs-module--response-success--b81fa";
export var standard = "on-site-service-hs-module--standard--072f1";
export var underline = "on-site-service-hs-module--underline--7f987";