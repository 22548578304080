import React from 'react';
import Layout from '../components/_common/_layout/layout';
import SEO from '../components/_common/seo/seo';
import { trackPage } from '../lib/track/track';
import { useStoryBlockCommon, useStoryblok } from '../lib/storyblok/storyblok';
import { PageOnSiteServiceStoryblok } from '../_shared/interfaces/storyblok';
import { IntlProvider } from 'react-intl';
import OnSiteService from '../components/on-site-service/on-site-service';
import SbEditable from 'storyblok-react';
import { ModalContextProvider } from '../context/modal/modal.context';
import { PageAccountStoryblokProvider } from '../context/storyblok/page-account-storyblok.context';
const OnSiteServicePage = ({ pageContext }: { pageContext: { story: { content: PageOnSiteServiceStoryblok; full_slug: string } } }) => {
    let story = pageContext.story;
    story = useStoryblok(story);
    const storyblokCommon = useStoryBlockCommon(story.full_slug);

    React.useEffect(() => {
        trackPage({
            country: process.env.GATSBY_COUNTRY,
            language: storyblokCommon.configuration.content.language,
            title: story.content.metadata.title,
            template: 'OnSiteService',
        });
    }, []);

    return (
        <IntlProvider locale={storyblokCommon.configuration.content.language}>
            <PageAccountStoryblokProvider blok={storyblokCommon.accountDetailPage.content}>
                <ModalContextProvider>
                    <Layout storyblokCommon={storyblokCommon}>
                        <SEO metadata={story.content.metadata} config={storyblokCommon.configuration.content} full_slug={story.full_slug} />
                        <SbEditable content={story.content}>
                            <OnSiteService blok={story.content} storyblokCommon={storyblokCommon} />
                        </SbEditable>
                    </Layout>
                </ModalContextProvider>
            </PageAccountStoryblokProvider>
        </IntlProvider>
    );
};

export default OnSiteServicePage;
