import '../../_scss/react-datepicker.scss';
import '../../_scss/_list-animation.scss';

import axios from 'axios';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import ko from 'date-fns/locale/ko';
import nl from 'date-fns/locale/nl';
import React, { useContext } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useIntl } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import SbEditable from 'storyblok-react';

import * as FormStylesDr from '../../_scss/modules/_forms.dr.module.scss';
import * as FormStylesHs from '../../_scss/modules/_forms.hs.module.scss';
import { IAddress } from '../../_shared/interfaces/address';
import { IArticle } from '../../_shared/interfaces/article';
import { IContact } from '../../_shared/interfaces/contact';
import { IProductGroup } from '../../_shared/interfaces/product-select';
import { IOrderInvoice } from '../../_shared/interfaces/order-invoice';
import { ICustomerRequestProductService } from '../../_shared/interfaces/customer-request';
import { PageOnSiteServiceStoryblok } from '../../_shared/interfaces/storyblok';
import AccountContext from '../../context/account/account.context';
import LoadingAnimationContext from '../../context/loading-animation/loading-animation.context';
import { getProductOptions } from '../../lib/article/article';
import { isGlobalDataSource, scrollToFirstError } from '../../lib/helpers/helpers';
import { getRichTextMarkup, StoryblokCommon } from '../../lib/storyblok/storyblok';
import { trackEvent } from '../../lib/track/track';
import AddressForm from '../_common/address-form/address-form';
import AddressListPicker from '../_common/address-list-picker/address-list-picker';
import Article from '../_common/article/article';
import ContactForm from '../_common/contact-form/contact-form';
import HsButtonSimple from '../_common/hs-button-simple/hs-button-simple';
import HsButton from '../_common/hs-button/hs-button';
import ManufacturerSelect from '../_common/manufacturer-select/manufacturer-select';
import * as OnSiteServiceStylesDr from './on-site-service.dr.module.scss';
import * as OnSiteServiceStylesHs from './on-site-service.hs.module.scss';

interface IOnSiteServiceData {
    language: string;
    contact: IContact;
    address: IAddress;
    invoice: IOrderInvoice;
    manufacturer: string;
    articles: IArticle[];
    desiredDate: string | undefined;
    disclaimer: boolean;
}

interface IRequestProductService {
    productName: string;
    serialNumber?: string;
    serviceName: string;
    serviceNumber: string;
    serviceKey: string;
    messageForWorkshop?: string;
    priceForService: number;
}

interface IOnSiteServiceRequest {
    language: string;
    contact: IContact;
    address: IAddress;
    payment: {
        address: {
            company: string | undefined;
            supplement: string | undefined;
            street: string | undefined;
            zipCode: string | undefined;
            city: string | undefined;
            country: string | undefined;
        };
        customerOrderNumber: string | undefined;
    };
    service: { manufacturer: { name: string | undefined; id: string | undefined }; productServices: IRequestProductService[] };
    desiredDate: string | undefined; // desiredDate: "2021-09-08T12:54:39.000Z"
    disclaimer: boolean;
}

const onSiteServiceDataDefault = {
    language: '',
    contact: { firstName: '', lastName: '', eMail: '', phoneNumber: '', language: '', country: '' },
    address: {
        id: undefined,
        company: '',
        street: '',
        city: '',
        country: '',
        postalCode: '',
        supplement: '',
    },
    invoice: {
        address: {
            id: undefined,
            company: '',
            street: '',
            city: '',
            country: '',
            postalCode: '',
            supplement: '',
        },
        customerOrderNumber: '',
    },
    manufacturer: '',
    articles: [{ product: '', service: '' }],
    desiredDate: undefined,
    disclaimer: false,
};

const OnSiteService = ({ blok, storyblokCommon }: { blok: PageOnSiteServiceStoryblok; storyblokCommon: StoryblokCommon }) => {
    const intl = useIntl();
    const STORAGE_ID = 'on-site-service';
    const accountContext = React.useContext(AccountContext);
    const loadingAnimationContext = React.useContext(LoadingAnimationContext);
    const FormStyles = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr : FormStylesHs;
    const OnSiteServiceStyles = storyblokCommon.configuration.content.theme === 'dr' ? OnSiteServiceStylesDr : OnSiteServiceStylesHs;
    const [onSiteServiceData, setOnSiteServiceData] = React.useState<IOnSiteServiceData>({
        ...onSiteServiceDataDefault,
        address: {
            ...onSiteServiceDataDefault.address,
            country:
                storyblokCommon.configuration.content.countries.length === 1 ? storyblokCommon.configuration.content.countries[0].id : '',
        },
        invoice: {
            ...onSiteServiceDataDefault.invoice,
            address: {
                ...onSiteServiceDataDefault.invoice.address,
                country:
                    storyblokCommon.configuration.content.countries.length === 1
                        ? storyblokCommon.configuration.content.countries[0].id
                        : '',
            },
        },
    });
    const [isReady, setIsReady] = React.useState(false);
    const [allManufacturers, setAllManufacturers] = React.useState<{ key: string; value: string }[]>([]);
    const [productOptions, setProductOptions] = React.useState<{ [manufacturer: string]: IProductGroup[] }>();
    const [minDate, setMinDate] = React.useState<Date | undefined>(undefined);
    const [showErrors, setShowErrors] = React.useState(false);
    const [pageState, setPageState] = React.useState<'standard' | 'confirmation' | 'error'>('standard');
    const formStylesError = storyblokCommon.configuration.content.theme === 'dr' ? FormStylesDr.error : FormStylesHs.error;
    const { showLoadingAnimation, hideLoadingAnimation } = useContext(LoadingAnimationContext);

    const updateSiteServiceData = (data: IOnSiteServiceData): void => {
        if (!data.desiredDate) {
            data.desiredDate = onSiteServiceData.desiredDate;
        }
        setOnSiteServiceData(data);
        sessionStorage.setItem(STORAGE_ID, JSON.stringify(data));
    };

    const updateArticle = (article: IArticle, articleIndex: number): void => {
        const articles = onSiteServiceData.articles;
        articles[articleIndex] = article;
        updateSiteServiceData({ ...onSiteServiceData, articles });
    };

    const removeArticle = (index: number): void => {
        if (onSiteServiceData.articles.length > 1) {
            const articles = onSiteServiceData.articles;
            articles.splice(index, 1);
            updateSiteServiceData({ ...onSiteServiceData, articles });
            scrollToArticle(index);
        }
    };

    const addArticle = (): void => {
        setShowErrors(false);
        const articles = onSiteServiceData.articles;
        articles.push({ product: '', service: '', notes: '' });
        updateSiteServiceData({ ...onSiteServiceData, articles });
        scrollToArticle(articles.length);
    };

    const scrollToArticle = (index: number): void => {
        if (typeof window !== 'undefined') {
            setTimeout(() => {
                const elem = document.querySelector('.' + OnSiteServiceStyles.articles + ' ul li:nth-child(' + index + ')');
                if (elem) {
                    try {
                        const position = elem.getBoundingClientRect();
                        window.scrollTo({
                            top: position.top + window.scrollY - 165,
                            left: 0,
                            behavior: 'smooth',
                        });
                    } catch (e) {}
                }
            }, 100);
        }
    };

    const isWeekday = (date: Date): boolean => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    const findFirstValidPickupDate = (minDateAddDays: number): Promise<Date> => {
        return new Promise((resolve) => {
            const d = new Date();
            d.setDate(d.getDate() + minDateAddDays);
            while (!isWeekday(d)) {
                d.setDate(d.getDate() + 1);
            }
            resolve(d);
        });
    };

    const getMaxDate = (maxDateAddDays: number): Date => {
        const d = new Date();
        d.setDate(d.getDate() + maxDateAddDays);
        return d;
    };

    const getProfileData = (): void => {
        if (!accountContext.accountData) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext
                .accountDataRequest()
                .catch(() => {})
                .finally(() => {
                    loadingAnimationContext.hideLoadingAnimation();
                });
        }

        if (!accountContext.addresses) {
            loadingAnimationContext.showLoadingAnimation();
            accountContext
                .addressesRequest()
                .catch(() => {})
                .finally(() => {
                    loadingAnimationContext.hideLoadingAnimation();
                });
        }
    };

    const submit = (): void => {
        const errors = getErrors();
        if (errors.length === 0) {
            trackEvent('serviceorder.sent', { serviceorder: { type: 'onsite_service' } });
            showLoadingAnimation();
            accountContext
                .updateToken()
                .then(() => {
                    apiRequest(process.env.GATSBY_COUNTRY ?? 'de', getRequestData(onSiteServiceData))
                        .then(() => {
                            if (typeof window !== 'undefined') {
                                window.scrollTo({ top: 0, behavior: 'auto' });
                            }
                            setPageState('confirmation');
                            resetData();
                        })
                        .catch(() => {
                            setPageState('error');
                        })
                        .finally(() => {
                            hideLoadingAnimation();
                        });
                })
                .catch(() => {
                    hideLoadingAnimation();
                    setPageState('error');
                });
        } else {
            setShowErrors(true);
            scrollToFirstError(formStylesError);
        }
    };

    const getRequestData = (data: IOnSiteServiceData): IOnSiteServiceRequest => {
        const productServices: IRequestProductService[] = [];

        const manufacturer = storyblokCommon.onSiteServices?.content.manufacturer.find((m) => {
            if (m.name === data.manufacturer) {
                return true;
            }
        });
        if (manufacturer) {
            data.articles.forEach((a) => {
                const product = manufacturer.products.find((p) => p.name === a.product);
                if (product) {
                    const service = product.services.find((s) => s._uid === a.service);
                    if (service) {
                        const item: ICustomerRequestProductService = {
                            productName: product.name,
                            serialNumber: a.serialNumber,
                            softwareVersion: a.softwareVersion,
                            serviceNumber: '--',
                            serviceKey: '---',
                            messageForWorkshop: a.notes,
                            priceForService: parseFloat(service.price.toString()),
                            serviceName: service.name,
                            serviceIntervalMonths: parseFloat((product.service_interval ?? 0).toString()),
                        };
                        productServices.push(item);
                    }
                }
            });
        }
        return {
            language: storyblokCommon.configuration.content.language,
            contact: { ...data.contact },
            address: { ...data.address },
            payment: {
                address: {
                    company: data.invoice.address?.company,
                    supplement: data.invoice.address?.supplement,
                    street: data.invoice.address?.street,
                    zipCode: data.invoice.address?.postalCode,
                    city: data.invoice.address?.city,
                    country: data.invoice.address?.country,
                },
                customerOrderNumber: data.invoice.customerOrderNumber,
            },
            desiredDate: data.desiredDate,
            disclaimer: data.disclaimer,
            service: {
                manufacturer: { name: data.manufacturer, id: manufacturer?.internal_id },
                productServices,
            },
        };
    };

    const apiRequest = async (countryCode: string, data: IOnSiteServiceRequest): Promise<any> => {
        const url = `${process.env.GATSBY_HS_API_BASE}/${process.env.GATSBY_HS_API_ONSITE}`.replace('%%COUNTRY-ID%%', countryCode);
        return await axios
            .post(url, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    };

    const resetData = (): void => {
        updateSiteServiceData({
            ...onSiteServiceDataDefault,
            address: { ...onSiteServiceDataDefault.address },
        });
    };

    const getErrors = (): string[] => {
        const errors = [];

        // contact
        if (
            !onSiteServiceData.contact ||
            !onSiteServiceData.contact.firstName ||
            !onSiteServiceData.contact.lastName ||
            !onSiteServiceData.contact.eMail ||
            !onSiteServiceData.contact.phoneNumber
        ) {
            errors.push('contact');
        }

        // address
        if (
            !onSiteServiceData.address ||
            !onSiteServiceData.address.company ||
            !onSiteServiceData.address.street ||
            !onSiteServiceData.address.city ||
            !onSiteServiceData.address.country ||
            !onSiteServiceData.address.postalCode
        ) {
            errors.push('address');
        }

        // invoice
        if (
            !onSiteServiceData.invoice.address ||
            !onSiteServiceData.invoice.address.company ||
            !onSiteServiceData.invoice.address.street ||
            !onSiteServiceData.invoice.address.city ||
            !onSiteServiceData.invoice.address.country ||
            !onSiteServiceData.invoice.address.postalCode
        ) {
            errors.push('invoice');
        }

        // manufacturer
        if (!onSiteServiceData.manufacturer) {
            errors.push('manufacturer');
        }

        // articles
        onSiteServiceData.articles.forEach((article) => {
            if (!article.product || !article.service || (article.serialNumberRepresentation === 'mandatory' && !article.serialNumber)) {
                errors.push('articles');
            }
        });

        // desiredDate
        if (!onSiteServiceData.desiredDate) {
            errors.push('desiredDate');
        }

        // desiredDate
        if (!onSiteServiceData.disclaimer) {
            errors.push('disclaimer');
        }

        return errors;
    };

    const getInitialContactData = (data: IOnSiteServiceData): IContact => {
        return data.contact;
    };

    React.useEffect(() => {
        if (accountContext.account) {
            getProfileData();
        }
    }, [accountContext.account]);

    React.useEffect(() => {
        let initialData = onSiteServiceData;
        let manufacturers: { key: string; value: string }[] = [];
        const filteredGlobalManufacturers = storyblokCommon.onSiteServices?.content.manufacturer.filter((m) => {
            if (isGlobalDataSource(storyblokCommon) && m.component.includes('global')) {
                return m;
            } else if (!isGlobalDataSource(storyblokCommon) && !m.component.includes('global')) {
                return m;
            }
        });

        if (filteredGlobalManufacturers) {
            manufacturers =
                filteredGlobalManufacturers.map((m) => {
                    return { key: m.name, value: m.name };
                }) ?? [];
        }

        setAllManufacturers(manufacturers);
        setProductOptions(getProductOptions(filteredGlobalManufacturers, storyblokCommon, intl));

        const storedData = sessionStorage.getItem(STORAGE_ID);
        if (storedData) {
            initialData = { ...initialData, ...JSON.parse(storedData) };
        }

        if (manufacturers.length === 1) {
            initialData = { ...initialData, manufacturer: manufacturers[0].key };
        }

        findFirstValidPickupDate(parseFloat(blok.datepicker_min_date_add_days.toString())).then((result) => {
            setMinDate(result);

            if (initialData.desiredDate) {
                const initialDesiredDate = new Date(initialData.desiredDate);
                if (initialDesiredDate < result) {
                    initialData.desiredDate = undefined;
                }
            }

            if (!initialData.desiredDate) {
                initialData = { ...initialData, desiredDate: result.toJSON() };
            }
            updateSiteServiceData(initialData);

            if (storyblokCommon.configuration.content.language === 'de') {
                registerLocale('de', de);
            } else if (storyblokCommon.configuration.content.language === 'fr') {
                registerLocale('fr', fr);
            } else if (storyblokCommon.configuration.content.language === 'ko') {
                registerLocale('ko', ko);
            } else if (storyblokCommon.configuration.content.language === 'nl') {
                registerLocale('nl', nl);
            }

            setIsReady(true);
        });
    }, []);

    return (
        <div className={OnSiteServiceStyles.onSiteService}>
            {pageState === 'confirmation' && (
                <div className={[OnSiteServiceStyles.response, OnSiteServiceStyles.responseSuccess].join(' ')}>
                    <h5>{blok.success_headline}</h5>
                    <p dangerouslySetInnerHTML={getRichTextMarkup(blok.success_copytext)} />
                </div>
            )}
            {pageState === 'error' && (
                <div className={[OnSiteServiceStyles.response, OnSiteServiceStyles.responseError].join(' ')}>
                    <h4>{blok.error_headline}</h4>
                    <p dangerouslySetInnerHTML={{ __html: blok.error_copytext.replace(/\r\n|\r|\n/g, '<br />') }} />
                    <div style={{ marginTop: '24px' }}>
                        <HsButton appearance="primary" onClick={() => setPageState('standard')} storyblokCommon={storyblokCommon}>
                            {blok.error_back_button}
                        </HsButton>
                    </div>
                </div>
            )}
            {pageState === 'standard' && (
                <div className={OnSiteServiceStyles.standard}>
                    {isReady && (
                        <>
                            {/*HEADER START*/}
                            <section>
                                <h4>{blok.headline}</h4>
                                <p dangerouslySetInnerHTML={{ __html: blok.copytext.replace(/\r\n|\r|\n/g, '<br />') }} />
                            </section>
                            {/*HEADER END*/}

                            {/*CONTACT START*/}
                            <section>
                                <div className={OnSiteServiceStyles.articlesHeader}>
                                    <h5>{blok.contact_headline}</h5>
                                </div>

                                <ContactForm
                                    initialData={getInitialContactData(onSiteServiceData)}
                                    onChange={(contact) => updateSiteServiceData({ ...onSiteServiceData, contact })}
                                    showErrors={showErrors}
                                    storyblokCommon={storyblokCommon}
                                    isOnSiteService={true}
                                />
                            </section>
                            {/*CONTACT END*/}

                            {/*MANUFACTURER START*/}
                            {allManufacturers.length > 1 && (
                                <section>
                                    <SbEditable content={blok.manufacturer_select[0]}>
                                        <ManufacturerSelect
                                            blok={blok.manufacturer_select[0]}
                                            manufacturers={allManufacturers}
                                            initialValue={onSiteServiceData.manufacturer}
                                            showErrors={showErrors}
                                            onChange={(manufacturer) =>
                                                updateSiteServiceData({
                                                    ...onSiteServiceData,
                                                    manufacturer,
                                                    articles: [{ product: '', service: '' }],
                                                })
                                            }
                                            storyblokCommon={storyblokCommon}
                                        />
                                    </SbEditable>
                                </section>
                            )}
                            {/*MANUFACTURER END*/}

                            {/*SERVICES START*/}
                            <section>
                                <div className={OnSiteServiceStyles.articles}>
                                    <div className={OnSiteServiceStyles.articlesHeader}>
                                        <h5>{blok.services_headline}</h5>
                                        <p dangerouslySetInnerHTML={{ __html: blok.services_copytext.replace(/\r\n|\r|\n/g, '<br />') }} />
                                    </div>
                                    <TransitionGroup className="product-list" component="ul">
                                        {onSiteServiceData.articles.map((article: IArticle, articleIndex: number) => {
                                            return (
                                                <CSSTransition key={articleIndex} timeout={500} classNames="item">
                                                    <li key={articleIndex}>
                                                        {articleIndex > 0 && (
                                                            <HsButtonSimple
                                                                icon="delete"
                                                                className={OnSiteServiceStyles.deleteArticle}
                                                                onClick={() => removeArticle(articleIndex)}
                                                                storyblokCommon={storyblokCommon}
                                                            >
                                                                {blok.services_delete}
                                                            </HsButtonSimple>
                                                        )}
                                                        {productOptions && (
                                                            <SbEditable content={blok.article[0]}>
                                                                <Article
                                                                    blok={blok.article[0]}
                                                                    storyblokCommon={storyblokCommon}
                                                                    manufacturer={onSiteServiceData.manufacturer}
                                                                    productOptions={productOptions}
                                                                    articleIndex={articleIndex}
                                                                    showErrors={showErrors}
                                                                    onChange={(a) => updateArticle(a, articleIndex)}
                                                                    initialValues={onSiteServiceData.articles[articleIndex]}
                                                                    source="on-site-service"
                                                                />
                                                            </SbEditable>
                                                        )}
                                                    </li>
                                                </CSSTransition>
                                            );
                                        })}
                                    </TransitionGroup>
                                    <div className={OnSiteServiceStyles.buttonWrapper}>
                                        <HsButton
                                            appearance="secondary"
                                            icon="plus"
                                            onClick={addArticle}
                                            disabled={!onSiteServiceData.manufacturer}
                                            storyblokCommon={storyblokCommon}
                                        >
                                            {blok.services_add}
                                        </HsButton>
                                    </div>
                                </div>
                            </section>
                            {/*SERVICES END*/}

                            {/*ADDRESS START*/}
                            <section>
                                <div className={OnSiteServiceStyles.articlesHeader}>
                                    <h5>{blok.address_headline}</h5>
                                </div>

                                {accountContext.account ? (
                                    <>
                                        <AddressListPicker
                                            selectedId={onSiteServiceData.address.id ?? undefined}
                                            onAddressChange={(addressId) => {
                                                const address = accountContext?.addresses?.find((a) => a.id === addressId);
                                                if (address) {
                                                    updateSiteServiceData({
                                                        ...onSiteServiceData,
                                                        address,
                                                    });
                                                }
                                            }}
                                            storyblokCommon={storyblokCommon}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <AddressForm
                                            showErrors={showErrors}
                                            initialAddress={onSiteServiceData.address}
                                            onChange={(address) => updateSiteServiceData({ ...onSiteServiceData, address })}
                                            storyblokCommon={storyblokCommon}
                                        />
                                    </>
                                )}
                            </section>
                            {/*ADDRESS END*/}

                            {/*INVOICE START*/}
                            <section>
                                {blok.invoice_headline && (
                                    <>
                                        <div className={OnSiteServiceStyles.articlesHeader}>
                                            <h5>{blok.invoice_headline}</h5>
                                        </div>

                                        {accountContext.account ? (
                                            <>
                                                <AddressListPicker
                                                    selectedId={onSiteServiceData.invoice.address?.id ?? undefined}
                                                    onAddressChange={(addressId) => {
                                                        const address = accountContext?.addresses?.find((a) => a.id === addressId);
                                                        if (address) {
                                                            updateSiteServiceData({
                                                                ...onSiteServiceData,
                                                                invoice: { ...onSiteServiceData.invoice, address },
                                                            });
                                                        }
                                                    }}
                                                    storyblokCommon={storyblokCommon}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <AddressForm
                                                    showErrors={showErrors}
                                                    initialAddress={onSiteServiceData.invoice.address}
                                                    onChange={(address) =>
                                                        updateSiteServiceData({
                                                            ...onSiteServiceData,
                                                            invoice: { ...onSiteServiceData.invoice, address },
                                                        })
                                                    }
                                                    storyblokCommon={storyblokCommon}
                                                />
                                            </>
                                        )}

                                        {blok.order_number_label && (
                                            <>
                                                <div className={OnSiteServiceStyles.underline} />
                                                <div className={FormStyles.formField}>
                                                    <label htmlFor="on-site-service-order-number">{blok.order_number_label}test</label>
                                                    <input
                                                        type="text"
                                                        onChange={(e) =>
                                                            updateSiteServiceData({
                                                                ...onSiteServiceData,
                                                                invoice: {
                                                                    ...onSiteServiceData.invoice,
                                                                    customerOrderNumber: e.target.value,
                                                                },
                                                            })
                                                        }
                                                        onBlur={(e) =>
                                                            updateSiteServiceData({
                                                                ...onSiteServiceData,
                                                                invoice: {
                                                                    ...onSiteServiceData.invoice,
                                                                    customerOrderNumber: e.target.value.trim(),
                                                                },
                                                            })
                                                        }
                                                        id="on-site-service-order-number"
                                                        value={onSiteServiceData.invoice.customerOrderNumber}
                                                        className={OnSiteServiceStyles.input}
                                                        maxLength={40}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </section>
                            {/*INVOICE END*/}

                            {/*DATEPICKER START*/}
                            <section>
                                <div className={OnSiteServiceStyles.desiredDate}>
                                    <div className={OnSiteServiceStyles.header}>
                                        <h5>{blok.desired_date_headline}</h5>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: blok.desired_date_copytext.replace(/\r\n|\r|\n/g, '<br />'),
                                            }}
                                        />
                                    </div>
                                    {minDate && storyblokCommon.configuration.content.language && (
                                        <div className={FormStyles.formField}>
                                            <label htmlFor="on-site-service-date">{blok.desired_date_label}</label>
                                            <DatePicker
                                                id="on-site-service-date"
                                                locale={storyblokCommon.configuration.content.language}
                                                dateFormat="P"
                                                filterDate={isWeekday}
                                                maxDate={getMaxDate(parseFloat(blok.datepicker_max_date_add_days.toString()))}
                                                minDate={minDate}
                                                selected={onSiteServiceData.desiredDate ? new Date(onSiteServiceData.desiredDate) : minDate}
                                                onChange={(e) => {
                                                    updateSiteServiceData({
                                                        ...onSiteServiceData,
                                                        desiredDate: e instanceof Date ? e.toJSON() : undefined,
                                                    });
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </section>
                            {/*DATEPICKER END*/}

                            {/*DISCLAIMER START*/}
                            <section className={OnSiteServiceStyles.disclaimer}>
                                <input
                                    id="on-site-service-disclaimer"
                                    type="checkbox"
                                    checked={onSiteServiceData.disclaimer}
                                    onChange={(e) => updateSiteServiceData({ ...onSiteServiceData, disclaimer: e.target.checked })}
                                />
                                <label
                                    htmlFor="on-site-service-disclaimer"
                                    className={FormStyles.checkboxLabel}
                                    dangerouslySetInnerHTML={getRichTextMarkup(blok.disclaimer_label)}
                                />

                                {showErrors && !onSiteServiceData.disclaimer && <p className={FormStyles.error}>{blok.disclaimer_error}</p>}
                            </section>
                            {/*DISCLAIMER END*/}

                            {/*BUTTON START*/}
                            <section>
                                <div className={OnSiteServiceStyles.bottomWrapper}>
                                    <div>
                                        <p>{blok.mandatory_legend}</p>
                                    </div>
                                    <div>
                                        <HsButton appearance="primary" onClick={submit} storyblokCommon={storyblokCommon}>
                                            {blok.submit_button}
                                        </HsButton>
                                    </div>
                                </div>
                            </section>
                            {/*BUTTON END*/}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default OnSiteService;
