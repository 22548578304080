// extracted by mini-css-extract-plugin
export var articles = "on-site-service-dr-module--articles--d1043";
export var articlesHeader = "on-site-service-dr-module--articles-header--f53b9";
export var bottomWrapper = "on-site-service-dr-module--bottom-wrapper--3132d";
export var buttonWrapper = "on-site-service-dr-module--button-wrapper--4556f";
export var deleteArticle = "on-site-service-dr-module--delete-article--ea34d";
export var desiredDate = "on-site-service-dr-module--desired-date--632e4";
export var disclaimer = "on-site-service-dr-module--disclaimer--a5897";
export var header = "on-site-service-dr-module--header--d1a88";
export var onSiteService = "on-site-service-dr-module--on-site-service--ee549";
export var response = "on-site-service-dr-module--response--9452d";
export var responseError = "on-site-service-dr-module--response-error--485c7";
export var responseSuccess = "on-site-service-dr-module--response-success--0bbda";
export var standard = "on-site-service-dr-module--standard--0c6fb";
export var underline = "on-site-service-dr-module--underline--2d8d9";